import _ from "lodash";
import {
  getSurvey,
  deleteSurvey,
  cloneSurvey,
  publishSurvey,
  getSurveyForTakerView,
  getContentUrlsFromBlobIds,
  getContentUrlsFromBlobIdsSurveyTaker,
} from "../../services/api";
import {
  remove as surveyListRemove,
  add as surveyListAdd,
  publishOrUnpublish as surveyListPublishOrUnpublish,
} from "../surveyList";
import { open as dialogOpen, trackAndShowError } from "../dialog";
import {
  THEME_GLOBAL_TECHNOLOGY,
  THEME_WALMART_CLASSIC,
  THEME_SAM_CLUB,
  THEME_INTERNATIONAL,
  ALREADY_SUBMITTED,
  DIALOG_TYPE,
} from "../../components/constants";
import {
  SET_AVAILABLE_LANGUAGES,
  SET_GREETINGS_TRANSLATION,
} from "./constants";
import i18next from "i18next";

export const REQUEST = "survey/REQUEST";
export const REQUEST_SUCCESS = "survey/REQUEST_SUCCESS";
export const REQUEST_FAILED = "survey/REQUEST_FAILED";
export const ALREADY_TAKEN = "survey/ALREADY_TAKEN";
export const REQUEST_QUESTIONS_SUCCESS = "survey/REQUEST_QUESTIONS_SUCCESS";
export const DELETE = "survey/DELETE";
export const DELETE_SUCCESS = "survey/DELETE_SUCCESS";
export const CLONE = "survey/CLONE";
export const CLONE_SUCCESS = "survey/CLONE_SUCCESS";
export const SURVEY_PUBLISH = "survey/SURVEY_PUBLISH";
export const UPDATE_NAME = "survey/UPDATE_NAME";
export const CLEAR_SURVEY_ID = "survey/CLEAR_SURVEY_ID";
export const PUBLISHED = "published",
  DRAFTS = "drafts",
  INACTIVE = "inactive";
export const PREVIEW_AUTH = "PREVIEW_AUTH";
export const SET_SURVEY_THEME = "SET_SURVEY_THEME";
export const SET_CLOSING_MESSAGE = "SET_CLOSING_MESSAGE";
export const SET_GREETINGS = "SET_GREETINGS";
export const SET_THEME_HEADER_AND_LOGO = "SET_THEME_HEADER_AND_LOGO";
export const SET_SURVEY_EXPERIENCE_TYPE = "SET_SURVEY_EXPERIENCE_TYPE";
export const SET_SURVEY_ID = "survey/SET_SURVEY_ID";
export const OPEN_SURVEY_SHARE = "survey/OPEN_SURVEY_SHARE";

export const UiThemes = {
  [THEME_GLOBAL_TECHNOLOGY]: {
    primaryColor: "#041f41",
    secondaryColor: "#041f41",
    fontColor: "#fff",
    backgroundColor: "#f6f6f6",
  },
  [THEME_WALMART_CLASSIC]: {
    primaryColor: "#0071ce",
    secondaryColor: "#0071ce",
    fontColor: "#fff",
    backgroundColor: "#f6f6f6",
  },
  [THEME_SAM_CLUB]: {
    primaryColor: "#0266A0",
    secondaryColor: "#cce0ec",
    fontColor: "#fff",
    backgroundColor: "#f6f6f6",
  },
  [THEME_INTERNATIONAL]: {
    primaryColor: "#0071ce",
    secondaryColor: "#0071ce",
    fontColor: "#fff",
    backgroundColor: "#f6f6f6",
  },
};

const initialState = {
  requestInProgress: false,
  deleteInProgress: false,
  requestCompleted: false,
  questionRequestCompleted: false,
  data: {},
  currentSurveyId: null,
  alreadyTaken: false,
  previewUnAuthorized: false,
  currentTheme: UiThemes[THEME_GLOBAL_TECHNOLOGY], // default theme setup
  closingMessage: null,
  greetings: null,
  availableLanguages: [],
  greetingsTranslations: {},
  visibleThemeHeaderNLogo: false,
  surveyExperienceType: "classic",
  surveyShareId: null,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case REQUEST:
      state.requestInProgress = true;
      state.requestCompleted = false;
      break;
    case REQUEST_SUCCESS:
      if (
        state.data[action.data.drupalId] &&
        state.data[action.data.drupalId].created
      ) {
        action.data.created = state.data[action.data.drupalId].created;
        action.data.modified = state.data[action.data.drupalId].modified;
      }
      state.currentSurveyId = action.data.drupalId;
      state.data[action.data.drupalId] = action.data;
      state.requestInProgress = false;
      state.requestCompleted = true;
      state.closingMessage = action.data.closingMessage;
      break;

    case REQUEST_FAILED:
      state.data[action.data.drupalId] = {};
      state.data[action.data.drupalId].surveyExists = false;
      break;
    case ALREADY_TAKEN:
      state.alreadyTaken = true;
      break;
    case PREVIEW_AUTH:
      state.previewUnAuthorized = action.data.previewUnAuthorized;
      break;
    case REQUEST_QUESTIONS_SUCCESS:
      state.data[action.drupalId].questions = action.data.questions;
      state.data[action.drupalId].hiddenQuestions = action.data.hiddenQuestions;
      state.requestInProgress = false;
      state.requestCompleted = true;
      state.questionRequestCompleted = true;
      break;
    case DELETE:
      state.deleteInProgress = action.id;
      break;
    case DELETE_SUCCESS:
      state.deleteInProgress = false;
      state.data = _.omit(state.data, action.id);
      break;
    case CLONE:
      state.cloneInProgress = true;
      break;
    case CLONE_SUCCESS:
      state.cloneInProgress = false;
      state.data[action.data.drupalId] = action.data;
      break;
    case SURVEY_PUBLISH:
      state.data[action.response].status = "published";
      state.data[action.response].modified = new Date(action.modified);
      break;
    case UPDATE_NAME:
      if (state.data[action.id]) {
        state.data[action.id].name = action.name;
      }
      break;
    case CLEAR_SURVEY_ID:
      state.currentSurveyId = initialState.currentSurveyId;
      break;
    case SET_SURVEY_ID:
      state.currentSurveyId = action.data;
      break;
    case SET_SURVEY_THEME:
      return {
        ...state,
        currentTheme: action.payload,
      };
    case SET_CLOSING_MESSAGE:
      return {
        ...state,
        closingMessage: action.payload,
      };
    case SET_GREETINGS:
      return {
        ...state,
        greetings: action.payload,
      };
    case SET_GREETINGS_TRANSLATION:
      return {
        ...state,
        greetingsTranslations: action.payload,
      };
    case SET_AVAILABLE_LANGUAGES:
      return {
        ...state,
        availableLanguages: action.payload,
      };
    case SET_THEME_HEADER_AND_LOGO:
      return {
        ...state,
        visibleThemeHeaderNLogo: action.payload,
      };
    case SET_SURVEY_EXPERIENCE_TYPE:
      return {
        ...state,
        surveyExperienceType: action.payload,
      };
    case OPEN_SURVEY_SHARE:
      return {
        ...state,
        surveyShareId: action.payload.surveyId,
      };
    default:
      break;
  }
  return state;
};

export const request = (surveyId) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST,
      surveyId,
    });

    const request = getSurvey(surveyId);

    return request
      .then((data) => dispatch(requestSuccess(data)))
      .catch((response) => {
        const trackMessage =
          "Error in Survey - id = " + surveyId + " while Create/Edit";
        dispatch(
          trackAndShowError("ERROR_WITH_SURVEY_ID", trackMessage, response)
        );
      });
  };
};

export const requestTakerView = (
  surveyId,
  preview,
  lang,
  skipSettingDefaultlanguage,
  campaignDetails
) => {
  return async (dispatch) => {
    dispatch({
      type: REQUEST,
      surveyId,
    });
    dispatch(previewAllowed({ previewUnAuthorized: false }));
    try {
      const data = await getSurveyForTakerView(
        surveyId,
        preview,
        lang,
        campaignDetails
      );
      !skipSettingDefaultlanguage &&
        i18next.changeLanguage(data.thirdPartySettings.defaultLang);
      let questions = [];
      let hiddenQuestions = [];
      const contentNames = [];
      _.each(data.questions, (question) => {
        if (question.hidden !== true) {
          questions.push(question);
        } else {
          hiddenQuestions.push(question);
        }

        if (question.type == "content" && question.imageUrl) {
          contentNames.push(question.imageUrl);
        } else if (question.type == "content" && question.videoUrl) {
          contentNames.push(question.videoUrl);
        }

        if (question.type == "image") {
          _.each(question.choices, (choice) => {
            contentNames.push(choice.imageUrl);
          });
        }
      });

      if (contentNames.length) {
        try {
          let contentUrlMapping;
          if (preview) {
            contentUrlMapping = await getContentUrlsFromBlobIds(
              contentNames,
              surveyId
            );
          } else {
            contentUrlMapping = await getContentUrlsFromBlobIdsSurveyTaker(
              contentNames,
              surveyId
            );
          }
          _.each(data.questions, (question) => {
            if (question.type == "content" && question.imageUrl) {
              question.imagePath = contentUrlMapping[question.imageUrl];
            } else if (question.type == "content" && question.videoUrl) {
              question.videoPath = contentUrlMapping[question.videoUrl];
            }

            if (question.type == "image") {
              _.each(question.choices, (choice) => {
                choice.imagePath = contentUrlMapping[choice.imageUrl];
              });
            }
          });
        } catch (error) {
          window.console.error("Error in Survey - id = " + surveyId);
        }
      }

      data.questions = questions;
      data.hiddenQuestions = hiddenQuestions;
      return dispatch(requestSuccess(data));
    } catch (response) {
      const code = response.data?.code;
      if (
        !response.status &&
        (code === "INVALID_PARAMS" ||
          code === "INVALID_CAMPAIGN_ID" ||
          code === "INVALID_CUSTOMER_ID" ||
          code === "INVALID_WAVE_ID")
      ) {
        return dispatch(
          requestFailed({ code: "INVALID_PARAMS", msg: response.data?.error })
        );
      } else if (
        response?.error === "Bad Request" &&
        response?.message?.includes("For input string:")
      ) {
        return dispatch(requestFailed({ code: "INVALID_PARAMS" }));
      } else if (response.surveyExists === false) {
        return dispatch(requestFailed(response));
      } else if (response.requireSSO === true) {
        //dont do anything and continue to show loader until redirection happens
      } else if (response.message == ALREADY_SUBMITTED) {
        return dispatch(requestAlreadyTaken(response));
      } else if (response.previewUnAuthorized === true) {
        return dispatch(previewAllowed(response));
      } else {
        const trackMessage = "Error in Survey - id = " + surveyId;
        return dispatch(
          trackAndShowError("ERROR_SURVEY_TAKER_VIEW", trackMessage, response)
        );
      }
    }
  };
};

export const requestSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_SUCCESS,
      data,
    });

    return data;
  };
};

export const requestFailed = (data) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_FAILED,
      data,
    });
    return data;
  };
};

export const requestAlreadyTaken = (data) => {
  return (dispatch) => {
    dispatch({
      type: ALREADY_TAKEN,
      data,
    });
    return data;
  };
};

export const previewAllowed = (data) => {
  return (dispatch) => {
    dispatch({
      type: PREVIEW_AUTH,
      data,
    });
  };
};

export const setCurrentSurveyId = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SURVEY_ID,
      data,
    });
  };
};

export const fnDelete = (id, surveyList, category, t) => {
  let dialogType = "";
  if (category == PUBLISHED) {
    dialogType = DIALOG_TYPE.DELETE_ACTIVE_SURVEY;
  } else if (category == DRAFTS) {
    dialogType = DIALOG_TYPE.DELETE_DRAFT_SURVEY;
  } else if (category == INACTIVE) {
    dialogType = DIALOG_TYPE.DELETE_INACTIVE_SURVEY;
  }
  return (dispatch) => {
    dispatch(
      dialogOpen({
        title: t("deleteTitle"),
        text: t("deleteDesc"),
        yesText: t("delete"),
        noText: t("cancel"),
        dialogType: dialogType,
        onYes: function () {
          dispatch({
            type: DELETE,
            id,
          });

          deleteSurvey(id)
            .then(function () {
              dispatch(surveyListRemove(id, surveyList, category));

              dispatch({
                type: DELETE_SUCCESS,
                id,
              });
            })
            .catch((response) => {
              const trackMessage = "Error in survey delete - id " + id;
              dispatch(
                trackAndShowError("ERROR_DELETE_SURVEY", trackMessage, response)
              );
            });
        },
      })
    );
  };
};

export const clone = (surveyId, title) => {
  return (dispatch) => {
    dispatch({ type: CLONE });
    cloneSurvey(surveyId, `${title}-clone`)
      .then(function (data) {
        dispatch({ type: CLONE_SUCCESS, data });
        dispatch(
          surveyListAdd({
            id: data.drupalId,
            title: data.name,
            created: data.created,
            modified: data.modified,
            is_publishable: data.is_publishable,
            responses: data.responses,
          })
        );
      })
      .catch((response) => {
        const trackMessage = "Error while cloning survey - " + surveyId;
        dispatch(
          trackAndShowError("ERROR_CLONE_SURVEY", trackMessage, response)
        );
      });
  };
};

export const publishOrUnpublish = (id, publish, modified_on) => {
  return (dispatch) => {
    return publishSurvey(id, publish, modified_on)
      .then((response) => {
        dispatch({
          type: SURVEY_PUBLISH,
          id,
          modified: response.modified_on,
        });

        dispatch(
          surveyListPublishOrUnpublish(id, publish, response.modified_on)
        );
      })
      .then(() => true)
      .catch((response) => {
        const trackMessage =
          "Error in survey - id " + id + " while publish/unpublish";
        if (response == "surveydata_not_latest") {
          dispatch(
            trackAndShowError(
              "ERROR_SURVEY_UPDATE",
              trackMessage,
              response,
              "Survey has been updated by someone else while you were working on it."
            )
          );
        } else {
          dispatch(
            trackAndShowError("ERROR_SURVEY_UPDATE", trackMessage, response)
          );
        }
      });
  };
};

export const updateName = (id, name) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NAME,
      id: id,
      name: name,
    });
  };
};

export const clearSurveyId = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SURVEY_ID,
    });
  };
};

export const setSurveyTheme = (themeName) => (dispatch) => {
  if (Object.prototype.hasOwnProperty.call(UiThemes, themeName)) {
    dispatch({
      type: SET_SURVEY_THEME,
      payload: UiThemes[themeName],
    });
  }
};

export const setClosingPageDetails = (closingMessage) => (dispatch) => {
  // title mandatory in older surveysays - backward compatibility
  if (
    closingMessage &&
    Object.prototype.hasOwnProperty.call(closingMessage, "title")
  ) {
    dispatch({
      type: SET_CLOSING_MESSAGE,
      payload: closingMessage,
    });
  }
};

export const setLandingPageDetails =
  (greetings, greetingsTranslations, availableLanguages) => (dispatch) => {
    // title mandatory in older surveysays - backward compatibility
    if (greetings && Object.prototype.hasOwnProperty.call(greetings, "title")) {
      dispatch({
        type: SET_GREETINGS,
        payload: greetings,
      });
    }
    if (greetingsTranslations) {
      dispatch({
        type: SET_GREETINGS_TRANSLATION,
        payload: greetingsTranslations,
      });
    }
    if (availableLanguages && availableLanguages.length > 1) {
      dispatch({
        type: SET_AVAILABLE_LANGUAGES,
        payload: availableLanguages,
      });
    }
  };

export const setVisibleThemeHeaderAndLogo = (value) => (dispatch) => {
  dispatch({
    type: SET_THEME_HEADER_AND_LOGO,
    payload: value,
  });
};

export const setSurveyExperienceType = (value) => (dispatch) => {
  dispatch({
    type: SET_SURVEY_EXPERIENCE_TYPE,
    payload: value,
  });
};

export const openShareSurvey = (surveyId) => (dispatch) => {
  dispatch({
    type: OPEN_SURVEY_SHARE,
    payload: {
      surveyId,
    },
  });
};

export const actions = {
  surveyRequest: request,
  surveyRequestTakerView: requestTakerView,
  surveyRequestSuccess: requestSuccess,
  surveyDelete: fnDelete,
  cloneSurvey: clone,
  surveyPublishOrUnpublish: publishOrUnpublish,
  surveyUpdateName: updateName,
  clearSurveyId: clearSurveyId,
  setSurveyTheme,
  setLandingPageDetails,
  setClosingPageDetails,
  setVisibleThemeHeaderAndLogo,
  setSurveyExperienceType,
  setCurrentSurveyId,
  openShareSurvey,
};
