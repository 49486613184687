import React from "react";
import s from "./style.module.scss";
import Icon from "../Icon";
import PropTypes from "prop-types";
import noop from "../../../services/noop/index";
import colors from "../../../style/colors.module.scss";

class IconButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
    this.hoverOn = this.hoverOn.bind(this);
    this.hoverOff = this.hoverOff.bind(this);
  }

  hoverOn() {
    this.setState({ hover: true });
  }

  hoverOff() {
    this.setState({ hover: false });
  }

  render() {
    return (
      <button
        className={[
          s.outer,
          this.props.className,
          this.props.handleClick ? s.clickable : "",
          this.state.hover && this.props.handleClick ? s.hover : "",
        ].join(" ")}
        onClick={this.props.handleClick}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
        aria-label={`${this.props.label} ${this.props.text || ""}`}
      >
        <Icon
          color={this.props.color}
          name={this.props.name}
          className={this.props.iconClassName}
        />
        {this.props.text && (
          <div className={[s.text, this.props.textClassName].join(" ")}>
            {this.props.text}
          </div>
        )}
        {this.props.showChildren && this.props.children}
      </button>
    );
  }
}

IconButton.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

IconButton.defaultProps = {
  color: colors.darker,
  text: "",
  handleClick: noop,
};

export default IconButton;
