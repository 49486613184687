/*eslint-disable*/
import _ from "lodash";
import { getDefaultRecommendationsTo } from "../commons";
import {
  OTHER,
  NONE,
  renderOtherByLang,
  renderNoneByLang,
  HIDDEN_VARIABLE,
  CHOICES_TYPE,
  DEFAULT_PARENT_ID,
  MEDIAUPLOAD,
} from "../../components/constants";
import uuid from "react-uuid";
import { EQUATION } from "../constants";

export const formatSurveyServerToApp = (r) => {
  let anonymize =
    r.audience === "customer" && r.campaign_enabled
      ? r.anonymize
      : r.anonymize === 1 || r.anonymize === true
      ? true
      : r.anonymous_access === true
      ? true
      : false;
  let allowMultipleSubmissions;

  //Set default value for allowMultipleSubmissions for old survey
  if (r.allowMultipleSubmissions === undefined) {
    allowMultipleSubmissions = r.anonymous_access;
  } else {
    allowMultipleSubmissions = r.allowMultipleSubmissions;
  }
  let data = {
    surveyExperienceType: r.experienceType,
    closingMessage: r.closingMessage,
    greetings: r.greetings,
    availableLanguages: r.availableLanguages,
    greetingsTranslations: r.greetingsTranslations,
    visibleThemeHeaderNLogo: r.visibleThemeHeaderNLogo,
    surveyExists: true,
    surveyAuthorized: true,
    drupalId: r.id,
    name: r.title,
    questionLabel: r.questionLabel,
    created: r.created_on,
    modified: r.modified_on,
    modifiedQuesList: r.modifiedQuestions,
    showQuestionLabels: r.showQuestionLabels ?? false,
    published: r.published_on,
    responses: r.submissions_count,
    isMultilingual: r.isMultilingual,
    views: r.views,
    status: r.status === "open" ? "published" : r.status,
    is_publishable: r.is_publishable != undefined ? r.is_publishable : true, //if is_publishable is not set(undefined) in a survey this will allow publish option without any restriction
    thirdPartySettings: {
      anonymous_access: r.anonymous_access,
      anonymize: anonymize,
      campaign_enabled: r.campaign_enabled,
      anonymize_editable: r.anonymize_editable,
      allowMultipleSubmissions: allowMultipleSubmissions,
      win_access: r.win_access,
      welcome_theme: r.welcome_theme,
      thanks_theme: r.thanks_theme,
      welcome_head: r.welcome_head,
      welcome_subhead: r.welcome_subhead,
      thanks_head: r.thanks_head,
      thanks_subhead: r.thanks_subhead,
      creatorId: r.owner_uid,
      is_publishable: r.is_publishable,
      selected_policy: r.selected_policy ? r.selected_policy : "",
      co_owners_uid: r.co_owners_uid,
      viewers_uid: r.viewers_uid,
      collaboraters: r.collaboraters || [],
      translators: r.translators || [],
      dataSources: r.dataSources,
      sourceFiles: r.sourceFiles,
      audience: r.audience,
      theme: r.theme,
      experienceType: r.experienceType,
      visibleThemeHeaderNLogo: r.visibleThemeHeaderNLogo,
      visibleProgressBar: r.visibleProgressBar,
      defaultLang: r.defaultLang,
      languages: r.languages,
      greetings: r.greetings,
      closingMessage: r.closingMessage,
      templateId: r.templateId,
      replaceTextValues: r.replaceTextValues,
      createdFromTemplate: r.createdFromTemplate,
    },
    questions: formatSurveyQuestionsServerToApp(r.questions),
    role: r.role,
    responseNotifications: r.responseNotifications,
  };
  return data;
};

export function formatQuestionTypeToApp(question) {
  let type = question["#type"];
  if (type === "webform_rating" && question["#max"] === 6) {
    return "faces";
  }
  return type;
}

export function formatQuestionTypeToServer(question) {
  let type = question.type;
  if (type === "faces") {
    type = "webform_rating";
  }
  return type;
}

export const formatSurveyQuestionsServerToApp = (elements) => {
  let questions = [];
  _.each(elements, (v) => {
    let type = formatQuestionTypeToApp(v);
    let question = {
      question: v["#title"],
      max: v["#max"],
      required: v["#required"] === true,
      type: type,
      id: v["#question_id"],
      criteria: v["#criteria"],
      choicesType: v[CHOICES_TYPE],
      //TODO: Check the need of the below data
      key: v["#webform_id"],
      questionLabel: v["#questionLabel"],
      help: v["#description"],
      hidden: v["#hidden"],
      "#conditions": v["#conditions"],
      intermediateEditMode:
        v["#replaceTextValues"] && v["#replaceTextValues"].length,
      replaceTextValues: v["#replaceTextValues"],
      label: v["#label"],
      contactForms: v["#contactForms"],
      randomized: v["#randomized"],
      deactivated: v["#deactivated"],
      isPipedFrom: v["#isPipedFrom"],
      isPipedTo: v["#isPipedTo"],
      maxFile: v["#maxFile"],
      fileSizeType: v["#fileSizeType"],
      checkedFileTypes: v["#checkedFileTypes"],
    };
    if (v["#formattingType"]) {
      question.formattingType = v["#formattingType"];
    }
    if (v["#formattingType"] && v["#source"]) {
      question.source = v["#source"];
    }
    question = formatQuestionDataByTypeToApp(v, question);
    questions.push(question);
  });
  return questions;
};
const noneQuestionId = "#noneQuestionId";

function formatChoices(choices) {
  let formattedChoice = _.cloneDeep(choices);
  formattedChoice = formattedChoice.map((item) => {
    // Creating default value if logic not coming from backend
    if (item.logic === undefined) {
      item.logic = {
        check: "alwaysTrue",
        tag: HIDDEN_VARIABLE.ALWAYS_TRUE,
        conditions: [],
      };
    } else if (
      item.logic.check &&
      item.logic.conditions?.length === 0 &&
      !item.source
    ) {
      item.logic.check = "alwaysTrue";
      item.logic.tag = HIDDEN_VARIABLE.ALWAYS_TRUE;
    } else if (
      item.logic.check &&
      item.logic.conditions?.length === 0 &&
      item.source
    ) {
      item.logic.check = "alwaysTrue";
    } else if (item.logic.check && item.logic.conditions?.length !== 0) {
      item.logic.check = "trueWithCondition";
    } else if (
      !item.logic.check &&
      (!item.logic.conditions || item.logic.conditions?.length === 0)
    ) {
      item.logic.check = "alwaysFalse";
    } else if (!item.logic.check && item.logic.conditions?.length !== 0) {
      item.logic.check = "falseWithCondition";
    }
    return item;
  });
  return formattedChoice;
}

export function formatQuestionDataByTypeToApp(serverData, appData) {
  appData["isDescriptionEnabled"] =
    serverData["#isDescriptionEnabled"] === undefined
      ? serverData["#description"] !== ""
      : serverData["#isDescriptionEnabled"];
  appData["parentId"] = serverData["#parentId"] || DEFAULT_PARENT_ID;

  //Assigning endpoint fields only for range question type
  if (appData["type"] === "range") {
    if (appData["max"] != 10) {
      appData["endpoint"] = serverData["#endpoint"];
      // commenting this section to fix precanned in templates assuming we dont have to do backward compatibility anymore
      // if (serverData["#endpoint"] && serverData["#endpoint"] !== "custom") {
      //   appData["endpointLeft"] = getEndpoint(serverData["#endpoint"], "left");
      //   appData["endpointRight"] = getEndpoint(
      //     serverData["#endpoint"],
      //     "right"
      //   );
      // } else {
      appData["endpointLeft"] = serverData["#endpointLeft"];
      appData["endpointRight"] = serverData["#endpointRight"];
      // }
    } else {
      if (serverData["#max"] === 10) {
        appData["recommendTo"] = serverData["#recommendTo"]
          ? serverData["#recommendTo"]
          : getDefaultRecommendationsTo();
        appData["npsTitle"] = serverData["#npsTitle"];
        appData["endpoint"] = serverData["#endpoint"];
        appData["endpointLeft"] = serverData["#endpointLeft"];
        appData["endpointRight"] = serverData["#endpointRight"];
      }
    }
    if (serverData["#preDefinedOption"] && serverData["#optionsList"]) {
      appData["preDefinedOption"] = serverData["#preDefinedOption"];
      appData["optionsList"] = serverData["#optionsList"];
      appData["scalePoints"] = serverData["#scalePoints"];
      const choices = serverData["#optionsList"].map((choice, index) => ({
        label: choice,
        value: index + 1,
      }));
      appData["choices"] = choices;
    }
  } else if (appData["type"] === "rangeNumeric") {
    appData["scale"] = serverData["#scale"];
    appData["endpoint"] = serverData["#endpoint"];
    appData["endpointLeft"] = serverData["#endpointLeft"];
    appData["endpointRight"] = serverData["#endpointRight"];
  } else if (
    appData["type"] === "radios" ||
    appData["type"] === "checkboxes" ||
    appData["type"] === "select"
  ) {
    if (appData["type"] === "radios" || appData["type"] === "checkboxes") {
      appData["multiple"] = serverData["#type"] === "checkboxes";
      appData["random"] =
        serverData["#random"] === undefined ? false : serverData["#random"];
      appData["asc"] =
        serverData["#asc"] === undefined ? false : serverData["#asc"];

      appData["other"] =
        serverData["#other"] === undefined ? false : serverData["#other"];
      if (appData["other"] === true && serverData["#otherQuestionId"]) {
        appData["otherQuestionId"] = serverData["#otherQuestionId"];
      }
      appData["none"] =
        serverData["#none"] === undefined ? false : serverData["#none"];

      if (appData["none"] === true && serverData[noneQuestionId]) {
        appData["noneQuestionId"] = serverData[noneQuestionId];
      }
    }
    let choices = [];
    serverData["#options"]?.forEach((option) => {
      choices.push({
        /*
                        label is the data that the end user sees in the screens such as Create Survey, Preview and SurveyAnswer/Taker.
                        value is the data that is used as option key/identifier in drupal webform.
                        This value data is derived from the data that the user enters in the Question cards of Radio & Checkboxes question type.
                        */
        label: option.key,
        value: option.value,
        logic: option.logic,
        id: option.id || uuid(),
        source: option.source,
      });
      // TO DO : need to check if its useful
      // if (
      //   (option.value == "Other" ||
      //     option.value == "Value_Other, if so specify") &&
      //   option.key == OTHER_LABEL_OLD
      // ) {
      //   appData["oldOtherLabel"] = true;
      // }
      if (option.value === NONE) {
        appData["none"] = true;
      }
    });
    choices = formatChoices(choices);

    appData["choices"] = choices;
    appData["preDefinedOption"] = serverData["#preDefinedOption"];
  } else if (appData["type"] === "variable") {
    let choices = [];
    serverData["#options"]?.forEach((option) => {
      choices.push({
        label: option.key,
        value: option.value,
        logic: option.logic,
        source: option.source,
        id: option.id || uuid(),
      });
    });
    choices = formatChoices(choices);

    appData["choices"] = choices;
    appData["variableType"] = serverData["#variableType"];
  } else if (appData["type"] === "hidden") {
    appData["jsonKey"] = serverData["#jsonKey"];
  } else if (appData["type"] === "matrix") {
    appData["matrixRow"] =
      serverData["#rowAndColumn"] && serverData["#rowAndColumn"]["matrixRow"];
    appData["matrixColumn"] =
      serverData["#rowAndColumn"] &&
      serverData["#rowAndColumn"]["matrixColumn"];
  } else if (appData["type"] === "ranking") {
    let choices = [];
    serverData["#options"] &&
      serverData["#options"]?.forEach((option) => {
        choices.push({
          label: option.key,
          value: option.value,
          logic: option.logic,
          source: option.source,
          id: option.id || uuid(),
        });
      });
    choices = formatChoices(choices);

    appData["rankingOptions"] = choices;
    appData["endpoint"] = serverData["#endpoint"];
    appData["endpointLeft"] = serverData["#endpointLeft"];
    appData["endpointRight"] = serverData["#endpointRight"];
  } else if (appData["type"] === "content") {
    appData["videoUrl"] = serverData["#videoUrl"];
    appData["videoLink"] = serverData["#videoLink"];
    appData["imageUrl"] = serverData["#imageUrl"];
  } else if (appData["type"] === "contact_form") {
    appData["contactForms"] = formatContactFormToApp(
      serverData["#contactForms"]
    );
  } else if (appData["type"] === "image") {
    let options = serverData["#options"],
      choices = [];
    if (options) {
      for (let option in options) {
        choices.push({
          label: options[option].key,
          value: options[option].value,
          imageUrl: options[option].imageUrl,
          id: option.id || uuid(),
        });
      }
    }
    appData["choices"] = choices;
    appData["isMultipleAnswers"] = serverData["#multipleAnswers"];
  } else if (appData["type"] === "number") {
    appData["range"] = serverData["#range"];
    appData["stepSize"] = serverData["#stepSize"];
    appData["interactionType"] = serverData["#interactionType"];
    appData["labels"] = serverData["#labels"];
  } else if (appData["type"] === "datetime") {
    appData["format"] = serverData["#format"];
    appData["customSelection"] = serverData["#customSelection"];
  } else if (appData["type"] === EQUATION) {
    appData["equations"] = serverData["#equations"];
    appData["variableType"] = serverData["#variableType"];
  }
  return appData;
}
//ned to have same seperate one
export function formatQuestionDataToServer(question, lang) {
  let newData = {
    "#question_id": question.id,
    "#parentId": question.parentId,
    "#type": formatQuestionTypeToServer(question),
    "#title": question.question,
    "#questionLabel": question.questionLabel,
    "#description": question.help,
    "#required": question.required,
    "#max": question.max,
    "#hidden": question.hidden,
    "#isDescriptionEnabled": question.isDescriptionEnabled,
    "#conditions": question["#conditions"],
    "#replaceTextValues": question.replaceTextValues,
    "#preDefinedOption": question.preDefinedOption,
    "#scalePoints": question.scalePoints,
    "#deactivated": question.deactivated,
    "#isPipedFrom": question.isPipedFrom,
    "#isPipedTo": question.isPipedTo,
  };
  if (question.formattingType) {
    newData["#formattingType"] = question.formattingType;
  }
  if (
    question.type === "radios" ||
    question.type === "checkboxes" ||
    question.type === "select"
  ) {
    newData[CHOICES_TYPE] = question.choicesType;
    if (question.choicesType !== "dynamic") {
      newData["#options"] = buildOptionsFromChoices(
        question.choices,
        question.other,
        question.none,
        lang,
        question.formattingType
      );
    } else {
      newData["#criteria"] = question.criteria;
    }
    if (question.type === "radios" || question.type === "checkboxes") {
      newData["#other"] = question.other;
      if (question.other === true) {
        newData["#otherQuestionId"] = question.otherQuestionId;
      }
      newData["#none"] = question.none;
      if (question.none === true) {
        newData[noneQuestionId] = question.noneQuestionId;
      }
      newData["#random"] = question.random;
      newData["#asc"] = question.asc;
    }
  } else if (question.type === "variable") {
    newData[CHOICES_TYPE] = question.choicesType;
    newData["#options"] = buildOptionsFromChoicesForCustom(question.choices);
    newData["#variableType"] = question.variableType;
  } else if (question.type === "range") {
    //newData['#isEndpointEnabled'] = question.isEndpointEnabled;
    if (question.endpointLeft && question.endpointRight) {
      newData["#endpoint"] = question.endpoint;
      newData["#endpointLeft"] = question.endpointLeft;
      newData["#endpointRight"] = question.endpointRight;
    }

    if (question.max === 10) {
      newData["#recommendTo"] = question.recommendTo;
      newData["#npsTitle"] = question.npsTitle
        ? question.npsTitle
        : question.question;
    }
    newData["#optionsList"] = question.choices?.map((choice) => choice.label);
  } else if (question.type === "rangeNumeric") {
    newData["#scale"] = question.scale;
    newData["#endpoint"] = question.endpoint;
    newData["#endpointLeft"] = question.endpointLeft;
    newData["#endpointRight"] = question.endpointRight;
  } else if (question.type === "hidden") {
    newData["#jsonKey"] = question.jsonKey;
  } else if (question.type === "matrix") {
    newData["#rowAndColumn"] = {
      matrixRow: question.matrixRow,
      matrixColumn: question.matrixColumn,
    };
  } else if (question.type === "ranking") {
    newData["#options"] = buildOptionsFromChoicesRanking(
      question.rankingOptions
    );
    newData["#endpoint"] = question.endpoint;
    newData["#endpointLeft"] = question.endpointLeft;
    newData["#endpointRight"] = question.endpointRight;
  } else if (question.type === "content") {
    if (question.videoUrl) {
      newData["#videoUrl"] = question.videoUrl;
    } else if (question.videoLink) {
      newData["#videoLink"] = question.videoLink;
    } else if (question.imageUrl) {
      newData["#imageUrl"] = question.imageUrl;
    }
  } else if (question.type === "contact_form") {
    newData["#contactForms"] = formatContactFormToServer(question.contactForms);
  } else if (question.type === "image") {
    newData["#multipleAnswers"] = question.isMultipleAnswers;
    newData["#options"] =
      question.choices &&
      question.choices.map((choice) => {
        return {
          key: choice.label,
          value: choice.value,
          imageUrl: choice.imageUrl,
        };
      });
  } else if (question.type === "number") {
    newData["#range"] = question.range;
    if (question.stepSize) {
      newData["#stepSize"] = Number(question.stepSize);
    }

    newData["#interactionType"] = question.interactionType;
    if (question.interactionType === "slider") {
      newData["#labels"] = question.labels;
    }
  } else if (question.type === "datetime") {
    newData["#format"] = question.format;
    newData["#customSelection"] = question.customSelection;
  } else if (question.type === "block") {
    newData["#randomized"] = question.randomized;
  } else if (question.type === EQUATION) {
    newData["#equations"] = filterEquationData(question.equations);
    newData["#variableType"] = question.variableType;
  } else if (question.type === MEDIAUPLOAD) {
    newData["#maxFile"] = question.maxFile;
    newData["#fileSizeType"] = question.fileSizeType;
    newData["#checkedFileTypes"] = question.checkedFileTypes;
  }
  return newData;
}

export const filterEquationData = (data) => {
  return data.map((val) => {
    switch (val.type) {
      case "question":
        return {
          type: val.type,
          questions: val.questions,
        };
      case "number":
      case "operand":
        return {
          type: val.type,
          value: val.value,
        };
      case "function":
        return {
          type: val.type,
          questions: val.questions,
          function: val.function,
        };
    }
  });
};

export const formatSaveSurveyAppToServer = (
  surveyData,
  lang,
  multiLingualQuestions
) => {
  //do empty check
  let translationsToBeSaved = {
    [lang]: surveyData.questions
      ? formatQuestions(surveyData, surveyData.questions, lang)
      : [],
  };
  if (Object.keys(multiLingualQuestions).length > 0) {
    Object.keys(multiLingualQuestions).forEach((eachLang) => {
      if (eachLang !== lang) {
        translationsToBeSaved = {
          ...translationsToBeSaved,
          ...{
            [eachLang]: formatQuestions(
              surveyData,
              multiLingualQuestions[eachLang],
              eachLang
            ),
          },
        };
      }
    });
  }

  return translationsToBeSaved;
};

export const formatTranslations = (
  surveyData,
  multiLingualQuestions,
  multiLingualClosingMessage,
  multiLingualGreetings,
  lang
) => {
  let translations = {
    [lang]: {
      questions: formatQuestions(surveyData, surveyData.questions, lang),
      closingMessage: surveyData.thirdPartySettings.closingMessage,
      greetings: surveyData.thirdPartySettings.greetings,
    },
  };

  if (Object.keys(multiLingualQuestions).length > 0) {
    Object.keys(multiLingualQuestions).forEach((eachLang) => {
      if (eachLang !== lang) {
        const eachLangQuesAndMsg = {
          [eachLang]: {
            questions: formatQuestions(
              surveyData,
              multiLingualQuestions[eachLang],
              eachLang
            ),
            closingMessage: multiLingualClosingMessage[eachLang],
            greetings: multiLingualGreetings[eachLang],
          },
        };
        translations = { ...translations, ...eachLangQuesAndMsg };
      }
    });
  }
  return translations;
};

export const formatModifiedQuestions = (
  surveyData,
  modifiedMultiLingualQuestions,
  lang
) => {
  let modifiedQuestions = {
    [lang]: surveyData.modifiedQuesList,
  };

  if (Object.keys(modifiedMultiLingualQuestions).length > 0) {
    Object.keys(modifiedMultiLingualQuestions).forEach((eachLang) => {
      if (eachLang !== lang) {
        const eachLangModifiedQues = {
          [eachLang]: modifiedMultiLingualQuestions[eachLang],
        };
        modifiedQuestions = { ...modifiedQuestions, ...eachLangModifiedQues };
      }
    });
  }
  return modifiedQuestions;
};

const formatQuestions = (r, questions, lang) => {
  let data = [];
  const hiddenQuestionIDs = []; // Remove duplicates
  if (r.hiddenQuestions) {
    r.hiddenQuestions = r.hiddenQuestions.filter((item) => {
      const id = item.id;
      if (hiddenQuestionIDs.indexOf(id) >= 0) {
        return false;
      }
      hiddenQuestionIDs.push(id);
      return true;
    });
  }
  for (let eachQuestion in questions) {
    const questionData = formatQuestionDataToServer(
      questions[eachQuestion],
      lang || r.thirdPartySettings?.defaultLang
    );
    if (questionData["#formattingType"]) {
      questionData["#source"] = "saved_survey";
    }
    data.push(questionData);
    if (questionData["#otherQuestionId"]) {
      for (let hq in r.hiddenQuestions) {
        let hiddenQuestion = r.hiddenQuestions[hq];
        if (hiddenQuestion.id === questionData["#otherQuestionId"]) {
          hiddenQuestion.question = renderOtherByLang(lang);
          hiddenQuestion.questionLabel = eachQuestion.questionLabel + ":Other";
          let hiddenData = formatQuestionDataToServer(hiddenQuestion);
          data.push(hiddenData);
        }
      }
    }
  }
  return data;
};

export const formatSurveyAppToServer = (r, lang) => {
  let data = [];

  const hiddenQuestionIDs = []; // Remove duplicates
  if (r.hiddenQuestions) {
    r.hiddenQuestions = r.hiddenQuestions.filter((item) => {
      const id = item.id;
      if (hiddenQuestionIDs.indexOf(id) >= 0) {
        return false;
      }
      hiddenQuestionIDs.push(id);
      return true;
    });
  }
  for (let element in r.questions) {
    const questionData = formatQuestionDataToServer(
      r.questions[element],
      lang || r.thirdPartySettings.defaultLang
    );
    data.push(questionData);
    if (questionData["#otherQuestionId"]) {
      for (let hq in r.hiddenQuestions) {
        let hiddenQuestion = r.hiddenQuestions[hq];
        if (hiddenQuestion.id === questionData["#otherQuestionId"]) {
          hiddenQuestion.question = renderOtherByLang(lang);
          hiddenQuestion.questionLabel =
            r.questions[element].questionLabel + ":Other";
          let hiddenData = formatQuestionDataToServer(hiddenQuestion);
          data.push(hiddenData);
        }
      }
    }
  }
  return data;
};

export const formatContactFormToServer = (contactForm) => {
  contactForm.forEach((field) => {
    for (let key in field) {
      if (key === "dropdownContent") {
        field[key] !== undefined &&
          field[key].forEach((item) => {
            for (let key in item) {
              delete item["_id"];
              item[`#${key}`] = item[key];
              delete item[key];
            }
          });
      }
      delete field["_id"];
      delete field["selected"];
      field[`#${key}`] = field[key];
      delete field[key];
    }
  });

  return contactForm;
};

export const formatContactFormToApp = (contactForm) => {
  const formatedContactForm = contactForm.map((field) => {
    for (let key in field) {
      if (key === "#dropdownContent") {
        field[key].forEach((item) => {
          for (let key in item) {
            delete item["_id"];
            item[`${key.slice(1)}`] = item[key];
            delete item[key];
          }
        });
      }
      delete field["_id"];
      field[`${key.slice(1)}`] = field[key];
      delete field[key];
    }
    return field;
  });

  return formatedContactForm;
};

export const getValue = (choice) => {
  const optionPrefix = "Value_";
  if (
    choice.value === OTHER ||
    choice.value === NONE ||
    (typeof choice.value === "string" && choice.value.includes(optionPrefix))
  ) {
    return choice.value;
  } else if (typeof choice.value === "string") {
    return optionPrefix + choice.value;
  } else {
    return optionPrefix + choice.label;
  }
};

export const buildOptionsFromChoicesForCustom = (choices) => {
  const data = [];
  const getKey = (choices, choice) => {
    return choices[choice].label;
  };

  /*This prefix is needed to prevent the sorting applied by js for number strings (ex "0" "5").
    This prefix will make every option values as strings thus avoids the number string sorting behavior.*/

  if (choices && choices.length > 0) {
    for (const choice in choices) {
      if (choices[choice].label) {
        const modifiedLogic = _.cloneDeep(choices[choice].logic) || {
          check: true,
          conditions: [],
          tag: HIDDEN_VARIABLE.ALWAYS_TRUE,
        };
        modifiedLogic.check =
          modifiedLogic.check === "alwaysTrue" ||
          modifiedLogic.check === "trueWithCondition" ||
          modifiedLogic.check === ""
            ? true
            : false;
        data.push({
          key: getKey(choices, choice),
          value: getValue(choices[choice]),
          logic: modifiedLogic,
          source: choices[choice].source,
          id: choices[choice].id || uuid(),
        });
      }
    }
  }
  return data;
};

export const buildOptionsFromChoicesRanking = (choices) => {
  let data = [];

  if (choices && choices.length > 0) {
    for (let choice in choices) {
      if (choices[choice].label) {
        const modifiedLogic = _.cloneDeep(choices[choice].logic) || {
          check: true,
          conditions: [],
          tag: HIDDEN_VARIABLE.ALWAYS_TRUE,
        };
        modifiedLogic.check =
          modifiedLogic.check === "alwaysTrue" ||
          modifiedLogic.check === "trueWithCondition" ||
          modifiedLogic.check === ""
            ? true
            : false;

        data.push({
          key: choices[choice].label,
          value: choices[choice].label,
          logic: modifiedLogic,
          source: choices[choice].source,
          id: choices[choice].id || uuid(),
        });
      }
    }
  }

  return data;
};

export const buildOptionsFromChoices = (
  choices,
  addOther,
  addNone,
  lang,
  formattingType
) => {
  let data = [];
  const getKey = (choices, choice) => {
    if (choices[choice].value === OTHER && addOther && !formattingType) {
      return renderOtherByLang(lang);
    }
    if (choices[choice].value === NONE && addNone && !formattingType) {
      return renderNoneByLang(lang);
    }
    return choices[choice].label;
  };

  const getValue = (choice) => {
    if (
      (choice.value === OTHER && addOther) ||
      (choice.value === NONE && addNone) ||
      (typeof choice.value === "string" && choice.value.includes(optionPrefix))
    ) {
      return choice.value;
    } else if (typeof choice.value === "string") {
      return optionPrefix + choice.value;
    } else {
      optionPrefix + choice.label;
    }
  };

  /*This prefix is needed to prevent the sorting applied by js for number strings (ex "0" "5").
    This prefix will make every option values as strings thus avoids the number string sorting behavior.*/
  let optionPrefix = "Value_";

  if (choices && choices.length > 0) {
    for (let choice in choices) {
      if (choices[choice].label) {
        const modifiedLogic = _.cloneDeep(choices[choice].logic) || {};
        modifiedLogic.check =
          modifiedLogic.check === "alwaysTrue" ||
          modifiedLogic.check === "trueWithCondition" ||
          modifiedLogic.check === ""
            ? true
            : false;

        data.push({
          key: getKey(choices, choice),
          value: getValue(choices[choice]),
          logic: modifiedLogic,
          source: choices[choice].source,
          id: choices[choice].id || uuid(),
        });
      }
    }
  }

  // if(addOther === true) {
  //   data.push({
  //     key: "Other, if so please tell us more",
  //     value: "Other"
  //   })
  // }
  return data;
};

export const formatTypes = (type) => {
  let formatQuestionLabelfromType = "";
  if (type === "textfield") {
    formatQuestionLabelfromType = "Short Form";
  } else if (type === "webform_rating") {
    formatQuestionLabelfromType = "Thumbs up/down";
  } else if (type === "datetime") {
    formatQuestionLabelfromType = "Date and Time";
  } else if (type === "number") {
    formatQuestionLabelfromType = "Number Input";
  } else if (type === "faces") {
    formatQuestionLabelfromType = "Faces";
  } else if (type === "rating") {
    formatQuestionLabelfromType = "Rating";
  } else if (type === "NPS") {
    formatQuestionLabelfromType = "Net Promotor Score";
  } else if (type === "rangeNumeric") {
    formatQuestionLabelfromType = "Range Numeric";
  } else if (type === EQUATION) {
    formatQuestionLabelfromType = "Equation Variable";
  } else {
    formatQuestionLabelfromType = type;
  }
  return formatQuestionLabelfromType;
};
